export default {
  'navBar.lang': '语言',
  'layout.user.link.help': '帮助',
  'layout.user.link.privacy': '隐私',
  'layout.user.link.terms': '条款',
  'app.copyright.produced': '蚂蚁集团体验技术部出品',
  'app.preview.down.block': '下载此页面到本地项目',
  'app.welcome.link.fetch-blocks': '获取全部区块',
  'app.welcome.link.block-list': '基于 block 开发，快速构建标准页面',

  // settingDrawer
  'app.setting.pagestyle': '整体风格设置',
  'app.setting.pagestyle.dark': '暗色菜单风格',
  'app.setting.pagestyle.light': '亮色菜单风格',
  'app.setting.pagestyle.inverted': '反转色菜单色风格',
  'app.setting.pagestyle.mode': '导航模式',
  'app.setting.pagestyle.top': '顶部布局模式',
  'app.setting.pagestyle.side': '侧边栏布局模式',
  'app.setting.pagestyle.mix': '混合布局模式',
  'app.setting.content-width.contentWidth': '内容区域宽度',
  'app.setting.content-width.fixed': '定宽',
  'app.setting.content-width.fluid': '流式',
  'app.setting.content-width.fixedHeader': '固定Header',
  'app.setting.content-width.fixSiderbar': '固定侧边栏菜单',
  'app.setting.content-width.splitMenus': '自动分割菜单',
  'app.setting.content-width.keepAlive': '缓存功能',
  'app.setting.content-width.accordionMode': '菜单手风琴模式',
  'app.setting.content-width.leftCollapsed': '侧边菜单折叠左侧',
  'app.setting.content-width.compactAlgorithm': '紧凑模式',
  'app.setting.content-area.title': '内容区域',
  'app.setting.content-area.header': '顶栏',
  'app.setting.content-area.footer': '页脚',
  'app.setting.content-area.menu': '菜单',
  'app.setting.content-area.watermark': '水印',
  'app.setting.content-area.menuHeader': '菜单头',
  'app.setting.content-area.multiTab': '多页签',
  'app.setting.content-area.multiTabFixed': '固定多页签',
  'app.setting.content-area.animationName': '动画',
  'app.setting.themecolor': '主题色',
  'app.setting.themecolor.dust': '薄暮',
  'app.setting.themecolor.volcano': '火山',
  'app.setting.themecolor.sunset': '日暮',
  'app.setting.themecolor.cyan': '明青',
  'app.setting.themecolor.green': '极光绿',
  'app.setting.themecolor.daybreak': '拂晓',
  'app.setting.themecolor.techBlue': '科技（默认）',
  'app.setting.themecolor.geekblue': '极客蓝',
  'app.setting.themecolor.purple': '酱紫',
  'app.setting.navigationmode': '导航模式',
  'app.setting.sidemenu': '侧边菜单布局',
  'app.setting.topmenu': '顶部菜单布局',
  'app.setting.fixedheader': '固定 Header',
  'app.setting.fixedsidebar': '固定侧边菜单',
  'app.setting.fixedsidebar.hint': '侧边菜单布局时可配置',
  'app.setting.hideheader': '下滑时隐藏 Header',
  'app.setting.hideheader.hint': '固定 Header 时可配置',
  'app.setting.othersettings': '其他设置',
  'app.setting.weakmode': '色弱模式',
  'app.setting.graymode': '灰色模式',
  'app.setting.copy': '拷贝设置',
  'app.setting.copyinfo': '拷贝成功，请到 config/default-settings.js 中替换默认配置',
  'app.setting.production.hint': '配置栏只在开发环境用于预览，生产环境不会展现，请拷贝后手动修改配置文件',
  'app.multiTab.title': '多页签',
  'app.multiTab.closeCurrent': '关闭当前',
  'app.multiTab.closeOther': '关闭其他',
  'app.multiTab.closeAll': '关闭全部',
  'app.multiTab.refresh': '刷新当前',
  'app.multiTab.closeRight': '关闭右侧',
  'app.multiTab.closeLeft': '关闭左侧',

  // menu菜单
  'menu.welcome': '欢迎',
  'menu.more-blocks': '更多区块',
  'menu.home': '首页',
  'menu.admin': '管理页',
  'menu.admin.sub-page': '二级管理页',
  'menu.login': '登录',
  'menu.register': '注册',
  'menu.register-result': '注册结果',
  'menu.dashboard': '仪表盘',
  'menu.dashboard.analysis': '分析页',
  'menu.dashboard.monitor': '监控页',
  'menu.dashboard.workplace': '工作台',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': '表单页',
  'menu.form.basic-form': '基础表单',
  'menu.form.step-form': '分步表单',
  'menu.form.step-form.info': '分步表单（填写转账信息）',
  'menu.form.step-form.confirm': '分步表单（确认转账信息）',
  'menu.form.step-form.result': '分步表单（完成）',
  'menu.form.advanced-form': '高级表单',
  'menu.link': '链接',
  'menu.link.iframe': 'AntDesign',
  'menu.link.antdv': 'AntDesignVue',
  'menu.link.external': '跳转百度',
  'menu.menu': '菜单',
  'menu.menu.menu1': '菜单1',
  'menu.menu.menu2': '菜单2',
  'menu.menu.menu3': '菜单1-1',
  'menu.menu3.menu1': '菜单1-1-1',
  'menu.menu3.menu2': '菜单1-1-2',
  'menu.menu.menu4': '菜单2-1',
  'menu.menu4.menu1': '菜单2-1-1',
  'menu.menu4.menu2': '菜单2-1-2',
  'menu.access': '权限模块',
  'menu.access.common': '通用权限',
  'menu.access.user': '普通用户',
  'menu.access.admin': '管理员',
  'menu.list': '列表页',
  'menu.list.table-list': '查询表格',
  'menu.list.basic-list': '标准列表',
  'menu.list.consult-table': '查询表格',
  'menu.list.crud-table': '增删改查表格',
  'menu.list.card-list': '卡片列表',
  'menu.list.search-list': '搜索列表',
  'menu.list.search-list.articles': '搜索列表（文章）',
  'menu.list.search-list.projects': '搜索列表（项目）',
  'menu.list.search-list.applications': '搜索列表（应用）',
  'menu.profile': '详情页',
  'menu.profile.basic': '基础详情页',
  'menu.profile.advanced': '高级详情页',
  'menu.result': '结果页',
  'menu.result.success': '成功页',
  'menu.result.fail': '失败页',
  'menu.exception': '异常页',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': '触发错误',
  'menu.account': '个人页',
  'menu.account.center': '个人中心',
  'menu.account.settings': '个人设置',
  'menu.account.trigger': '触发报错',
  'menu.account.logout': '退出登录',

  // 晨烨菜单
  'menu.map': '系统集成',
  'menu.event': '平台事件',
  'menu.linkage': '场景联动',
  'menu.file': '文件管理',
  'menu.eam': '资产管理',
  'menu.eam.dashboard': '仪表盘',
  'menu.eam.provider': '供应商管理',
  'menu.eam.department': '部门管理',
  'menu.eam.extra': '资产台账',
  'menu.eam.mainplan': '维保计划',
  'menu.eam.inspection': '设备巡检',
  'menu.eam.work-order': '工单',
  'menu.eam.inventory': '库存管理',
  'menu.eam.report': '工作日报',
  'menu.eam.notify': '站内信',
  'menu.tv': '电视墙',
}
